<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <validation-observer
            ref="DetailRules"
            tag="form"
          >
            <b-button-toolbar justify>
              <div style="width:50%" />
              <div>
                <b-button
                  v-if="(Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking) || (Role == 'company admin')"
                  v-b-tooltip.hover="'Save'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="UpdateDetail()"
                >
                  <font-awesome-icon
                    :icon="['far', 'save']"
                    size="lg"
                  />
                </b-button>
              </div>
            </b-button-toolbar>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Carrier Name"
                    rules="max:100"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Carrier Name:</b></i></label>
                      <b-form-input
                        id="Carrier"
                        v-model="CarrierDetail.Carrier"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Carrier"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="SCAC"
                    rules="max:4"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>SCAC:</b></i></label>
                      <b-form-input
                        id="SCAC"
                        v-model="CarrierDetail.SCAC"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="SCAC"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">

          <AgGrid
            ref="ScheduleGrid"
            :grid="aggrid"
            @LoadAll="LoadAll"
          >
            <template v-slot:lftbtn>
              <div>
                <b-button
                  v-if="((Role != 'readonly' && Role != 'readonly admin') && (Permission.CreateBooking)) || (Role == 'company admin')"
                  v-b-tooltip.hover="'Create a New entry'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-1"
                  @click="CreateNewSchedule()"
                ><font-awesome-icon
                   :icon="['far', 'plus-square']"
                   class="mr-50"
                   size="lg"
                 />
                  <span class="align-middle">New Schedule</span></b-button>
              </div>
            </template>
          </AgGrid>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="CreateScheduleModal"
      ref="CreateSchedule"
      title="New Schedule"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Transit Time"
                rules="required|min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Transit Time:</b></i></label>
                  <b-form-input
                    v-model="ScheduleDetail.TransitTime"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Service"
                rules="max:150"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Service:</b></i></label>
                  <b-form-input
                    v-model="ScheduleDetail.Service"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Service"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="S/C Service Holder"
                rules="max:50"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>S/C Service Holder:</b></i></label>
                  <b-form-input
                    v-model="ScheduleDetail.ContractType"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="S/C Service Holder"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Destination Terminal"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Destination Terminal:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.TerminalLocation"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="SI Cutoff"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>SI Cutoff:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.SICutoffDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="POL"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>POL:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.POL"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CY Cutoff"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>CY Cutoff:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.CYCutoffDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="POD"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>POD:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.POD"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="POD ETA"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>POD ETA:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.PODETA"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Final Dest."
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Final Dest.:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.FinalDest"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Final Dest. ETA"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Final Dest. ETA:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.FinalDestETA"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <app-collapse>
          <app-collapse-item title="Feeder Vessel">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Name"
                    rules="max:50"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Vessel Name:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.FeederVesselName"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Voyage"
                    rules="max:50"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Vessel Voyage:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.FeederVoyage"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Voyage"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETD"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>ETD:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.FeederETD"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>ETA:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.FeederETA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <app-collapse>
              <app-collapse-item title="Connecting/VIA">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Connecting/VIA POL"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Connecting/VIA POL:</b></i></label>
                          <v-select
                            v-model="ScheduleDetail.FeederVIAPOL"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Connecting/VIA POD"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Connecting/VIA POD:</b></i></label>
                          <v-select
                            v-model="ScheduleDetail.FeederVIAPOD"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </app-collapse-item>
          <app-collapse-item title="Mother Vessel">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Name"
                    rules="max:50|required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Vessel Name:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.MotherVesselName"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Voyage"
                    rules="max:50|required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Vessel Voyage:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.MotherVoyage"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETD"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>ETD:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.MotherETD"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>ETA:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.MotherETA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <app-collapse>
              <app-collapse-item title="Connecting/VIA">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Connecting/VIA POL"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Connecting/VIA POL:</b></i></label>
                          <v-select
                            v-model="ScheduleDetail.MotherVIAPOL"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Connecting/VIA POD"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Connecting/VIA POD:</b></i></label>
                          <v-select
                            v-model="ScheduleDetail.MotherVIAPOD"
                            :options="POLPODOptions"
                            label="location"
                            placeholder="Select a Location"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </app-collapse-item>
        </app-collapse>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewSchedule()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateScheduleModal"
      ref="UpdateSchedule"
      title="Update Schedule"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdateScheduleRules"
        tag="form"
      >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Transit Time"
                rules="required|min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Transit Time:</b></i></label>
                  <b-form-input
                    v-model="ScheduleDetail.TransitTime"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Service"
                rules="max:150"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Service:</b></i></label>
                  <b-form-input
                    v-model="ScheduleDetail.Service"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Service"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="S/C Service Holder"
                rules="max:50"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>S/C Service Holder:</b></i></label>
                  <b-form-input
                    v-model="ScheduleDetail.ContractType"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="S/C Service Holder"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Destination Terminal"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Destination Terminal:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.TerminalLocation"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="SI Cutoff"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>SI Cutoff:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.SICutoffDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="POL"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>POL:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.POL"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CY Cutoff"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>CY Cutoff:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.CYCutoffDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="POD"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>POD:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.POD"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="POD ETA"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>POD ETA:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.PODETA"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Final Dest."
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Final Dest.:</b></i></label>
                  <v-select
                    v-model="ScheduleDetail.FinalDest"
                    :options="POLPODOptions"
                    label="location"
                    placeholder="Select a Location"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Final Dest. ETA"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Final Dest. ETA:</b></i></label>
                  <b-form-datepicker
                    v-model="ScheduleDetail.FinalDestETA"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <app-collapse>
          <app-collapse-item title="Feeder Vessel">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Name"
                    rules="max:50"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Vessel Name:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.FeederVesselName"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Voyage"
                    rules="max:50"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Vessel Voyage:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.FeederVoyage"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Voyage"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETD"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>ETD:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.FeederETD"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>ETA:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.FeederETA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="F/V POL"
                    rules=""
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>F/V POL:</b></i></label>
                      <v-select
                        v-model="ScheduleDetail.FeederVIAPOL"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="F/V POD"
                    rules=""
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>F/V POD:</b></i></label>
                      <v-select
                        v-model="ScheduleDetail.FeederVIAPOD"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Mother Vessel">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Name"
                    rules="max:50|required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Vessel Name:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.MotherVesselName"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Vessel Voyage"
                    rules="max:50|required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Vessel Voyage:</b></i></label>
                      <b-form-input
                        v-model="ScheduleDetail.MotherVoyage"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Vessel Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETD"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>ETD:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.MotherETD"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="ETA"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>ETA:</b></i></label>
                      <b-form-datepicker
                        v-model="ScheduleDetail.MotherETA"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="M/V POL"
                    rules=""
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>M/V POL:</b></i></label>
                      <v-select
                        v-model="ScheduleDetail.MotherVIAPOL"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="M/V POD"
                    rules=""
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>M/V POD:</b></i></label>
                      <v-select
                        v-model="ScheduleDetail.MotherVIAPOD"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <div class="divider my-2">
          <div class="divider-text">
            {{ 'Last Updated: ' + ScheduleDetail.UpdatedOn + ' by ' + ScheduleDetail.UpdatedBy + ' | Created: ' + ScheduleDetail.CreatedOn + ' by ' + ScheduleDetail.CreatedBy }}
          </div>
        </div>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateSchedule()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>
<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingCarrierViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AgGrid,
    AppCollapseItem,
  },
  data() {
    return {
      required,
      aggrid: [],
      CarrierId: this.$route.params.carrierId,
      CarrierDetail: {
        Carrier: '',
        SCAC: '',
      },
      ScheduleId: '',
      ScheduleDetail: {
        ContractType: '',
        POL: '',
        CYCutoffDate: '',
        SICutoffDate: '',
        FeederVesselName: '',
        FeederVoyage: '',
        FeederETD: '',
        FeederETA: '',
        FeederVIAPOL: '',
        FeederVIAPOD: '',
        MotherVesselName: '',
        MotherVoyage: '',
        MotherETD: '',
        MotherETA: '',
        MotherVIAPOL: '',
        MotherVIAPOD: '',
        Service: '',
        POD: '',
        PODETA: '',
        FinalDestETA: '',
        TransitTime: 0,
        TerminalLocation: '',
        FinalDest: '',
        UpdatedBy: '',
        UpdatedOn: '',
        CreatedBy: '',
        CreatedOn: '',
      },
      POLPODOptions: [],
      Permission: {},
      Role: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('SailingSchedule')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'S/C Holder', field: 'contractType', minWidth: 100,
      },
      {
        headerName: 'Service', field: 'service', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Transit Time', field: 'transitTime', minWidth: 85,
      },
      {
        headerName: 'POL', field: 'polName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'CY Cutoff',
        field: 'cyCutoffDate',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'POD', field: 'podName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'POD ETA',
        field: 'podeta',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Final Dest', field: 'finalDestName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Final Dest ETA',
        field: 'finalDestETA',
        minWidth: 150,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Terimal', field: 'terminalLocationName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Feeder Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'feederVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.feederVesselName || ''} | ${params.data.feederVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'feederETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'feederETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'feederVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'feederVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Mother Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'motherVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.motherVesselName || ''} | ${params.data.motherVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'motherETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'motherETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'motherVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'motherVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },

    ]

    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ScheduleGrid.saveState('SailingSchedule') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ScheduleGrid.saveState('SailingSchedule') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ScheduleGrid.saveState('SailingSchedule') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ScheduleGrid.saveState('SailingSchedule') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(ScheduleId) {
      this.ScheduleId = ScheduleId
      this.$refs.UpdateSchedule.show()
      this.ScheduleDetail = {
        POL: '',
        CYCutoffDate: '',
        SICutoffDate: '',
        FeederVesselName: '',
        FeederVoyage: '',
        FeederETD: '',
        MotherVesselName: '',
        MotherVoyage: '',
        MotherETD: '',
        Service: '',
        POD: '',
        PODETA: '',
        FinalDestETA: '',
        TransitTime: 0,
        TerminalLocation: '',
        FinalDest: '',
        UpdatedBy: '',
        UpdatedOn: '',
        CreatedBy: '',
        CreatedOn: '',
      }
      this.LoadSchedule(ScheduleId)
    },
    DoubleClickOpenPopup(params) {
      this.ScheduleId = params.data.id
      this.$refs.UpdateSchedule.show()
      this.ScheduleDetail = {
        POL: '',
        CYCutoffDate: '',
        SICutoffDate: '',
        FeederVesselName: '',
        FeederVoyage: '',
        FeederETD: '',
        MotherVesselName: '',
        MotherVoyage: '',
        MotherETD: '',
        Service: '',
        POD: '',
        PODETA: '',
        FinalDestETA: '',
        TransitTime: 0,
        TerminalLocation: '',
        FinalDest: '',
        UpdatedBy: '',
        UpdatedOn: '',
        CreatedBy: '',
        CreatedOn: '',
      }
      this.LoadSchedule(this.ScheduleId)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCarrierDetail(), this.LoadScheduleList(), this.LoadPOLPODList()])
        .then(() => {
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
          this.LoadingEnd()
        })
    },
    LoadCarrierDetail() {
      return axios.get(`/booking/carrier/${this.CarrierId}`)
        .then(resp => {
          const response = resp.data
          const respdata = response
          this.CarrierDetail.Carrier = respdata.carrier
          this.CarrierDetail.SCAC = respdata.scac
        })
    },
    LoadPOLPODList() {
      // Loading POL POD List
      return axios.get('/booking/codes/polpod')
        .then(rowData => {
          this.POLPODOptions = rowData.data
        })
    },
    LoadScheduleList() {
      axios.get(`/booking/carrier/${this.CarrierId}/sailingschedule`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ScheduleGrid.ResetColumns()
        })
    },
    LoadSchedule(ScheduleId) {
      this.ResetObject()
      axios.get(`/booking/carrier/${this.CarrierId}/sailingschedule/${ScheduleId}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response

        this.ScheduleDetail.ContractType = respdata.contractType

        this.ScheduleDetail.POL = this.POLPODOptions.find(option => option.id === respdata.pol)
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.CYCutoffDate = this.CheckNull(respdata.cyCutoffDate) ? new Date(respdata.cyCutoffDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.cyCutoffDate) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.SICutoffDate = this.CheckNull(respdata.siCutoffDate) ? new Date(respdata.siCutoffDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.siCutoffDate) : null : null

        this.ScheduleDetail.FeederVesselName = respdata.feederVesselName
        this.ScheduleDetail.FeederVoyage = respdata.feederVoyage
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.FeederETD = this.CheckNull(respdata.feederETD) ? new Date(respdata.feederETD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.feederETD) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.FeederETA = this.CheckNull(respdata.feederETA) ? new Date(respdata.feederETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.feederETA) : null : null
        this.ScheduleDetail.FeederVIAPOD = this.POLPODOptions.find(option => option.id === respdata.feederVIAPOD)
        this.ScheduleDetail.FeederVIAPOL = this.POLPODOptions.find(option => option.id === respdata.feederVIAPOL)

        this.ScheduleDetail.MotherVesselName = respdata.motherVesselName
        this.ScheduleDetail.MotherVoyage = respdata.motherVoyage
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.MotherETD = this.CheckNull(respdata.motherETD) ? new Date(respdata.motherETD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.motherETD) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.MotherETA = this.CheckNull(respdata.motherETA) ? new Date(respdata.motherETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.motherETA) : null : null
        this.ScheduleDetail.MotherVIAPOD = this.POLPODOptions.find(option => option.id === respdata.motherVIAPOD)
        this.ScheduleDetail.MotherVIAPOL = this.POLPODOptions.find(option => option.id === respdata.motherVIAPOL)

        this.ScheduleDetail.Service = respdata.service
        this.ScheduleDetail.POD = this.POLPODOptions.find(option => option.id === respdata.pod)
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.PODETA = this.CheckNull(respdata.podeta) ? new Date(respdata.podeta).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.podeta) : null : null
        // eslint-disable-next-line no-nested-ternary
        this.ScheduleDetail.FinalDestETA = this.CheckNull(respdata.finalDestETA) ? new Date(respdata.finalDestETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.finalDestETA) : null : null
        this.ScheduleDetail.TransitTime = respdata.transitTime
        this.ScheduleDetail.TerminalLocation = this.POLPODOptions.find(option => option.id === respdata.terminalLocation)
        this.ScheduleDetail.FinalDest = this.POLPODOptions.find(option => option.id === respdata.finalDest)
        this.ScheduleDetail.CreatedBy = respdata.createdBy
        this.ScheduleDetail.CreatedOn = respdata.createdOn
        this.ScheduleDetail.UpdatedBy = respdata.updatedBy
        this.ScheduleDetail.UpdatedOn = respdata.updatedOn
        this.LoadingEnd()
      })
    },
    CreateNewSchedule() {
      this.ResetObject()
      this.$refs.CreateSchedule.show()
    },
    SaveNewSchedule() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const SailingScheduleModel = {
            CarrierId: this.CarrierId,
            ContractType: this.ScheduleDetail.ContractType,
            POL: this.CheckNull(this.ScheduleDetail.POL) ? this.ScheduleDetail.POL.id : null,
            CYCutoffDate: this.CheckNull(this.ScheduleDetail.CYCutoffDate) ? new Date(this.ScheduleDetail.CYCutoffDate).toJSON().split('T')[0] : null,
            SICutoffDate: this.CheckNull(this.ScheduleDetail.SICutoffDate) ? new Date(this.ScheduleDetail.SICutoffDate).toJSON().split('T')[0] : null,
            FeederVesselName: this.ScheduleDetail.FeederVesselName,
            FeederVoyage: this.ScheduleDetail.FeederVoyage,
            FeederETD: this.CheckNull(this.ScheduleDetail.FeederETD) ? new Date(this.ScheduleDetail.FeederETD).toJSON().split('T')[0] : null,
            FeederETA: this.CheckNull(this.ScheduleDetail.FeederETA) ? new Date(this.ScheduleDetail.FeederETA).toJSON().split('T')[0] : null,
            FeederVIAPOL: this.CheckNull(this.ScheduleDetail.FeederVIAPOL) ? this.ScheduleDetail.FeederVIAPOL.id : null,
            FeederVIAPOD: this.CheckNull(this.ScheduleDetail.FeederVIAPOD) ? this.ScheduleDetail.FeederVIAPOD.id : null,
            MotherVesselName: this.ScheduleDetail.MotherVesselName,
            MotherVoyage: this.ScheduleDetail.MotherVoyage,
            MotherETD: this.CheckNull(this.ScheduleDetail.MotherETD) ? new Date(this.ScheduleDetail.MotherETD).toJSON().split('T')[0] : null,
            MotherETA: this.CheckNull(this.ScheduleDetail.MotherETA) ? new Date(this.ScheduleDetail.MotherETA).toJSON().split('T')[0] : null,
            MotherVIAPOL: this.CheckNull(this.ScheduleDetail.MotherVIAPOL) ? this.ScheduleDetail.MotherVIAPOL.id : null,
            MotherVIAPOD: this.CheckNull(this.ScheduleDetail.MotherVIAPOD) ? this.ScheduleDetail.MotherVIAPOD.id : null,
            Service: this.ScheduleDetail.Service,
            POD: this.CheckNull(this.ScheduleDetail.POD) ? this.ScheduleDetail.POD.id : null,
            PODETA: this.CheckNull(this.ScheduleDetail.PODETA) ? new Date(this.ScheduleDetail.PODETA).toJSON().split('T')[0] : null,
            FinalDest: this.CheckNull(this.ScheduleDetail.FinalDest) ? this.ScheduleDetail.FinalDest.id : null,
            FinalDestETA: this.CheckNull(this.ScheduleDetail.FinalDestETA) ? new Date(this.ScheduleDetail.FinalDestETA).toJSON().split('T')[0] : null,
            TransitTime: this.ScheduleDetail.TransitTime,
            TerminalLocation: this.CheckNull(this.ScheduleDetail.TerminalLocation) ? this.ScheduleDetail.TerminalLocation.id : null,

          }

          axios.post(`/booking/carrier/${this.CarrierId}/sailingschedule`, SailingScheduleModel)
            .then(() => {
              this.LoadingEnd()
              this.$refs.CreateSchedule.hide()
              this.DisplaySuccess('Schedule data has been saved.').then(() => {
                this.LoadScheduleList()
                this.ResetObject()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateSchedule.hide()
              this.DisplayError('New schedule cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New schedule cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateSchedule() {
      this.$refs.UpdateScheduleRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const SailingScheduleModel = {
            CarrierId: this.CarrierId,
            ContractType: this.ScheduleDetail.ContractType,
            POL: this.CheckNull(this.ScheduleDetail.POL) ? this.ScheduleDetail.POL.id : null,
            CYCutoffDate: this.CheckNull(this.ScheduleDetail.CYCutoffDate) ? new Date(this.ScheduleDetail.CYCutoffDate).toJSON().split('T')[0] : null,
            SICutoffDate: this.CheckNull(this.ScheduleDetail.SICutoffDate) ? new Date(this.ScheduleDetail.SICutoffDate).toJSON().split('T')[0] : null,
            FeederVesselName: this.ScheduleDetail.FeederVesselName,
            FeederVoyage: this.ScheduleDetail.FeederVoyage,
            FeederETD: this.CheckNull(this.ScheduleDetail.FeederETD) ? new Date(this.ScheduleDetail.FeederETD).toJSON().split('T')[0] : null,
            FeederETA: this.CheckNull(this.ScheduleDetail.FeederETA) ? new Date(this.ScheduleDetail.FeederETA).toJSON().split('T')[0] : null,
            FeederVIAPOL: this.CheckNull(this.ScheduleDetail.FeederVIAPOL) ? this.ScheduleDetail.FeederVIAPOL.id : null,
            FeederVIAPOD: this.CheckNull(this.ScheduleDetail.FeederVIAPOD) ? this.ScheduleDetail.FeederVIAPOD.id : null,
            MotherVesselName: this.ScheduleDetail.MotherVesselName,
            MotherVoyage: this.ScheduleDetail.MotherVoyage,
            MotherETD: this.CheckNull(this.ScheduleDetail.MotherETD) ? new Date(this.ScheduleDetail.MotherETD).toJSON().split('T')[0] : null,
            MotherETA: this.CheckNull(this.ScheduleDetail.MotherETA) ? new Date(this.ScheduleDetail.MotherETA).toJSON().split('T')[0] : null,
            MotherVIAPOL: this.CheckNull(this.ScheduleDetail.MotherVIAPOL) ? this.ScheduleDetail.MotherVIAPOL.id : null,
            MotherVIAPOD: this.CheckNull(this.ScheduleDetail.MotherVIAPOD) ? this.ScheduleDetail.MotherVIAPOD.id : null,
            Service: this.ScheduleDetail.Service,
            POD: this.CheckNull(this.ScheduleDetail.POD) ? this.ScheduleDetail.POD.id : null,
            PODETA: this.CheckNull(this.ScheduleDetail.PODETA) ? new Date(this.ScheduleDetail.PODETA).toJSON().split('T')[0] : null,
            FinalDest: this.CheckNull(this.ScheduleDetail.FinalDest) ? this.ScheduleDetail.FinalDest.id : null,
            FinalDestETA: this.CheckNull(this.ScheduleDetail.FinalDestETA) ? new Date(this.ScheduleDetail.FinalDestETA).toJSON().split('T')[0] : null,
            TransitTime: this.ScheduleDetail.TransitTime,
            TerminalLocation: this.CheckNull(this.ScheduleDetail.TerminalLocation) ? this.ScheduleDetail.TerminalLocation.id : null,

          }

          axios.put(`booking/carrier/${this.CarrierId}/sailingschedule/${this.ScheduleId}`, SailingScheduleModel)
            .then(() => {
              this.LoadingEnd()
              this.$refs.UpdateSchedule.hide()
              this.DisplaySuccess('Schedule data has been updated.').then(() => {
                this.LoadScheduleList()
                this.ResetObject()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.UpdateSchedule.hide()
              this.DisplayError('Schedule cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Schedule cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateDetail() {
      this.$refs.DetailRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const CarriersModel = {
            Carrier: this.CarrierDetail.Carrier,
            SCAC: this.CarrierDetail.SCAC,
          }

          axios.put(`/booking/carrier/${this.CarrierId}`, CarriersModel)
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Carrier data has been updated.')
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Carrier cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Carrier cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    Delete(ScheduleId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/carrier/${this.CarrierId}/sailingschedule/${ScheduleId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Schedule has been removed.')
              .then(() => {
                // redirect
                this.LoadScheduleList()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Schedule cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ResetObject() {
      this.ScheduleDetail = {
        ContractType: '',
        POL: '',
        CYCutoffDate: '',
        SICutoffDate: '',
        FeederVesselName: '',
        FeederVoyage: '',
        FeederETD: '',
        FeederETA: '',
        FeederVIAPOL: '',
        FeederVIAPOD: '',
        MotherVesselName: '',
        MotherVoyage: '',
        MotherETD: '',
        MotherETA: '',
        MotherVIAPOL: '',
        MotherVIAPOD: '',
        Service: '',
        POD: '',
        PODETA: '',
        FinalDestETA: '',
        TransitTime: 0,
        TerminalLocation: '',
        FinalDest: '',
        UpdatedBy: '',
        UpdatedOn: '',
        CreatedBy: '',
        CreatedOn: '',
      }
    },
  },
}
</script>
